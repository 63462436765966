import React, { FC } from 'react';

import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';
import getDropDownItem from 'gatsby-theme-dettol/src/utils/dropDownControl';

import 'gatsby-theme-dettol/src/components/ProductIngredients/IngredientItem/IngredientItem.scss';
import './IngreedientItemOverride.scss';

const IngredientItem: FC<ProductTypes.TGroup> = ({
  properties: { iconName, composition, name },
}) => (
  <>
    <dt className="dt-ingridient-item__block">
      <IconCustom icon={getDropDownItem(iconName)} />
      <span className="dt-ingridient-item__title">{name}</span>
    </dt>

    <dd className="dt-ingridient-item__value" dangerouslySetInnerHTML={{ __html: composition }} />
  </>
);

export default IngredientItem;
