import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';

import { MediaInfoBox, Media, Info } from 'gatsby-theme-dettol/src/components/common/MediaInfoBox';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import IngredientItem from 'gatsby-theme-dettol/src/components/ProductIngredients/IngredientItem';

import { IProductIngredients } from 'gatsby-theme-dettol/src/components/ProductIngredients/models';

import './ProductIngredients.scss';

const ProductIngredients: FC<IProductIngredients> = (props) => (
  <div className="dt-ingredients">
    <MediaInfoBox isSwap sectionLandmark={props.titleRegular}>
      <Media>
        <UmbracoImage image={props.image} alt={props.imageAlt} />
      </Media>

      <Info>
        <h2 className="dt-ingredients__title">{props.titleRegular}</h2>
        <dl className="dt-ingredients__list">
          {props.group?.map((item, iter) => (
            <IngredientItem key={`ingredients-groups-${iter}`} {...item} />
          ))}
        </dl>
        <p
          className="dt-ingredients__description"
          dangerouslySetInnerHTML={{ __html: props.description || '' }}
        />
        {props.buttonText ? (
          <div className="dt-media-info-box__info-btn">
            <Button variant="light" href={extractUrlFromMultiPicker(props.buttonLink)}>
              {props.buttonText}
            </Button>
          </div>
        ) : null}
      </Info>
    </MediaInfoBox>
  </div>
);

export default ProductIngredients;
